<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"><div class="ui-type-display-lg">{{ $t('family.settings.addresses.title') }}</div> </CCol>
      <CCol sm="auto">
        <CButton color="primary" @click="onAddAddress()">
          {{ $t('family.settings.addresses.btn_title') }}
        </CButton>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <ejs-grid
          :dataSource="dm"
          :allowPaging="false"
          :allowSorting="true"
          ref="grid"
        >
          <e-columns>
            <e-column
              field="id"
              headerText="ID"
              :isPrimaryKey="true"
              :visible="false"
            ></e-column>
            <e-column
              field="to"
              :headerText="$t('models.family_address.to')"
            ></e-column>
            <e-column
              field="address"
              :headerText="$t('models.family_address.address')"
            ></e-column>
            <e-column
              field="city"
              :headerText="$t('models.family_address.city')"
            ></e-column>
            <e-column
              field="postal_code"
              :headerText="$t('models.family_address.postal_code')"
              :template="postalCodeTemplate"
            ></e-column>
            <e-column
              field="country"
              :headerText="$t('models.family_address.country')"
            ></e-column>
            <e-column
              field="default_ship"
              :headerText="$t('models.family_address.default_ship')"
              :template="defaultShipTemplate"
            ></e-column>
            <e-column
              field="default_bill"
              :headerText="$t('models.family_address.default_bill')"
              :template="defaultBillTemplate"
            ></e-column>
            <e-column headerText="" :template="actionsTemplate"></e-column>
          </e-columns>
        </ejs-grid>
      </CCardBody>
    </CCard>

    <AddressesModal
      :show.sync="modal.show"
      :isEdit="modal.isEdit"
      :title="modal.title"
      :values="modal.values"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { GetDataManagerNew } from "../../../ds";
import AddressesModal from "./AddressesModal";
import errorResponse from "../../../helpers/error";
import EventBus from "../../../helpers/EventBus";

export default {
  components: {
    AddressesModal,
  },

  data() {
    const familyId = this.$store.state.connections.current.family.id;
    const dm = GetDataManagerNew("auth_faddress", [familyId]);
    return {
      dm: dm,

      defaultShipTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-left">
                <div class="text-success" v-if="isTrue()">
                  <font-awesome-icon icon="check-circle" />
                </div>
                <div class="text-danger" v-else>
                  <font-awesome-icon icon="times-circle" />
                </div>
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              isTrue() {
                return this.data["default_ship"] === true;
              },
            },
          }),
        };
      },

      postalCodeTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div>
                {{ getPostalCode() }}
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              getPostalCode() {
                return this.data.postal_code.postal_code;
              },
            },
          }),
        };
      },

      defaultBillTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-left">
                <div class="text-success" v-if="isTrue()">
                  <font-awesome-icon icon="check-circle" />
                </div>
                <div class="text-danger" v-else>
                  <font-awesome-icon icon="times-circle" />
                </div>
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              isTrue() {
                return this.data["default_bill"] === true;
              },
            },
          }),
        };
      },

      actionsTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-end">
                <dropdown-menu :items="actions" :select="onActionSelect">
                  <font-awesome-icon icon="ellipsis-h"
                /></dropdown-menu>
              </div>`,
            data() {
              const ADDRESS_ACTIONS = [
                { id: "EDIT", text: this.$t('family.settings.addresses.actions.edit') },
                { id: "DELETE", text: this.$t('family.settings.addresses.actions.remove') },
              ];
              return {
                ADDRESS_ACTIONS: ADDRESS_ACTIONS
              }
            },
            computed: {
              actions() {
                return this.ADDRESS_ACTIONS;
              },
            },
            methods: {
              onActionSelect: function (args) {
                const { id } = args.item;

                if (id === "EDIT") {
                  EventBus.$emit("address:edit", this.data);
                }

                if (id === "DELETE") {
                  if (
                    confirm(
                      this.$t('family.settings.addresses.actions.remove_confirm', [this.data.to])
                    ) == true
                  ) {
                    EventBus.$emit("address:remove", this.data);
                  }
                }
              },
            },
          }),
        };
      },

      // actionsTemplate: () => {
      //   return { template: AddressesTableActions };
      // },

      error: errorResponse(),
      modal: {
        show: false,
        isEdit: false,
        title: this.$t('family.settings.addresses.modal.title'),
        values: {},
      },
    };
  },

  mounted() {
    EventBus.$on("address:remove", this.onRemoveAddress);
    EventBus.$on("address:edit", this.onEditAddress);
    EventBus.$on("address:refresh", this.onRefresh);
  },

  beforeDestroy() {
    EventBus.$off("address:remove", this.onRemoveAddress);
    EventBus.$off("address:edit", this.onEditAddress);
    EventBus.$off("address:refresh", this.onRefresh);
  },

  methods: {
    removeAddress(id) {
      this.error.reset();
      this.dm
        .remove("", id)
        .then(() => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Indirizzo rimosso con successo",
            color: "success",
            autohide: true,
          });
          this.onRefresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    onRemoveAddress(item) {
      this.removeAddress(item.id);
    },

    onAddAddress() {
      this.modal.title = this.$t('family.settings.addresses.modal.title');
      this.modal.isEdit = false;
      this.modal.values = {};
      this.modal.show = true;
    },

    onEditAddress(item) {
      this.modal.title = "Modifica indirizzo";
      this.modal.isEdit = true;
      this.modal.values = { ...item };
      this.modal.show = true;
    },

    onRefresh() {
      this.$refs.grid.refresh();
    },
  },
};
</script>
